import PropTypes from "prop-types";

function IncidentIcon({ colorProp }) {
  const color = colorProp || "#1D2D4E";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="28"
      viewBox="0 0 28 23"
      fill="none"
      style={{ marginLeft: "-4px", marginTop: "-3px" }}
    >
      <path
        d="M15.3677 16.1411C15.1198 16.0547 14.8589 16.011 14.5963 16.0118H6.89873C6.63874 16.0095 6.38039 16.0532 6.13569 16.1411C5.79593 16.2561 5.48757 16.4485 5.23501 16.7032C4.79177 17.1453 4.54216 17.7452 4.54102 18.3713C4.54079 18.3996 4.5462 18.4278 4.55695 18.454C4.5677 18.4803 4.58356 18.5041 4.60362 18.5242C4.62367 18.5442 4.64752 18.5601 4.67378 18.5708C4.70003 18.5816 4.72815 18.587 4.75652 18.5868H16.7381C16.7665 18.5871 16.7946 18.5817 16.8209 18.571C16.8472 18.5602 16.8711 18.5444 16.8911 18.5243C16.9112 18.5042 16.9271 18.4804 16.9378 18.4541C16.9485 18.4278 16.9539 18.3997 16.9536 18.3713C16.9551 17.8805 16.803 17.4016 16.5186 17.0016C16.2342 16.6017 15.8318 16.3008 15.3677 16.1411Z"
        fill={color}
      />
      <path
        d="M6.89898 15.5786H14.5953C14.9064 15.5776 15.2154 15.6301 15.5087 15.7339V10.825C15.5074 9.5625 15.0054 8.35209 14.1129 7.45927C13.2203 6.56645 12.01 6.06413 10.7476 6.0625C9.48553 6.0646 8.27585 6.56717 7.38387 7.45997C6.49189 8.35278 5.99043 9.56293 5.9895 10.825V15.7295H5.9939C6.28449 15.6268 6.5908 15.5758 6.89898 15.5786Z"
        fill={color}
      />
      <path
        d="M10.7563 4.05836C10.8706 4.05825 10.9801 4.0128 11.0609 3.932C11.1417 3.8512 11.1872 3.74164 11.1873 3.62737V0.430992C11.1873 0.316686 11.1419 0.207062 11.0611 0.126235C10.9802 0.0454082 10.8706 0 10.7563 0C10.642 0 10.5324 0.0454082 10.4516 0.126235C10.3707 0.207062 10.3253 0.316686 10.3253 0.430992V3.62693C10.3253 3.74128 10.3707 3.85095 10.4515 3.93184C10.5323 4.01274 10.642 4.05825 10.7563 4.05836Z"
        fill={color}
      />
      <path
        d="M4.69622 6.76909C4.78611 6.83405 4.89758 6.86185 5.00745 6.84673C5.11731 6.8316 5.21712 6.77471 5.28611 6.68787C5.3551 6.60104 5.38797 6.49095 5.37787 6.38051C5.36777 6.27007 5.31548 6.16777 5.23188 6.09489L2.73301 4.10221C2.6891 4.06393 2.63788 4.03495 2.58246 4.01702C2.52703 3.99909 2.46855 3.99258 2.41054 3.99789C2.35253 4.00319 2.29619 4.0202 2.24494 4.04789C2.19369 4.07558 2.14858 4.11337 2.11234 4.15898C2.0761 4.20459 2.04949 4.25707 2.0341 4.31325C2.01872 4.36944 2.01488 4.42816 2.02283 4.48587C2.03077 4.54358 2.05033 4.59908 2.08033 4.64901C2.11032 4.69895 2.15013 4.74229 2.19735 4.77641L4.69622 6.76909Z"
        fill={color}
      />
      <path
        d="M3.5478 12.2734C3.51494 12.2734 3.48219 12.2771 3.45017 12.2844L0.334268 12.996C0.231163 13.0198 0.140393 13.0806 0.0792511 13.1669C0.0181086 13.2533 -0.00913473 13.3591 0.00271046 13.4643C0.0145556 13.5694 0.0646615 13.6665 0.143484 13.7371C0.222306 13.8077 0.324337 13.8468 0.430142 13.847C0.462416 13.847 0.494585 13.8433 0.526016 13.836L3.64191 13.1249C3.74531 13.1016 3.83649 13.041 3.89802 12.9547C3.95956 12.8684 3.98714 12.7624 3.9755 12.6571C3.96385 12.5517 3.9138 12.4544 3.8349 12.3836C3.756 12.3128 3.65379 12.2736 3.5478 12.2734Z"
        fill={color}
      />
      <path
        d="M21.4827 13.1626C21.4527 13.1146 21.4134 13.0731 21.3672 13.0404C21.321 13.0077 21.2688 12.9845 21.2135 12.9721L18.0981 12.261C18.066 12.2538 18.0333 12.2501 18.0005 12.25C17.8944 12.2501 17.7921 12.2892 17.7132 12.3599C17.6342 12.4307 17.584 12.528 17.5723 12.6334C17.5606 12.7388 17.5882 12.8448 17.6497 12.9311C17.7113 13.0175 17.8025 13.0781 17.9059 13.1014L21.0209 13.8121C21.0529 13.8196 21.0857 13.8233 21.1185 13.8231C21.2162 13.8237 21.3112 13.7908 21.3876 13.7299C21.464 13.669 21.5173 13.5838 21.5385 13.4884C21.5638 13.3767 21.5437 13.2595 21.4827 13.1626Z"
        fill={color}
      />
      <path
        d="M16.5575 6.83936C16.655 6.83922 16.7497 6.80622 16.8262 6.74568L19.325 4.75257C19.4145 4.6813 19.4719 4.57744 19.4847 4.46383C19.4975 4.35021 19.4647 4.23616 19.3934 4.14676C19.3222 4.05735 19.2183 3.99992 19.1047 3.9871C18.9911 3.97427 18.877 4.0071 18.7876 4.07837L16.2888 6.07149C16.2189 6.12722 16.1681 6.20327 16.1434 6.28912C16.1187 6.37498 16.1213 6.46639 16.1508 6.55072C16.1803 6.63505 16.2353 6.70813 16.3081 6.75984C16.381 6.81155 16.4681 6.83933 16.5575 6.83936Z"
        fill={color}
      />
    </svg>
  );
}

IncidentIcon.propTypes = {
  colorProp: PropTypes.string,
};

IncidentIcon.defaultProps = {
  colorProp: "#1D2D4E",
};

export default IncidentIcon;
