import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import themeColor from "../../../styles/theme";
import {
  BeltechLogoIcon,
  FinanceIcon,
  HomeIcon,
  SignalIcon,
  MaintenanceIcon,
  ViolationIcon,
  LogoutIcon,
  VehicleDetail,
  UpdateVehicle,
  UserAccessManagement,
  IncidentIcon,
} from "../../assets/svg";
import {
  FINANCE,
  HOME,
  JUNCTION_VIDEOS,
  MAINTENANCE,
  OVERVIEW,
  SIGNAL_CONTROL,
  SIGNAL_STATUS,
  SIGNAL_SUMMARY,
  TRAFFIC_ANALYTICS,
  UPDATE_VEHICLE_DETAILS_PERMISSION,
  UPDATE_VEHICLE_DETAILS,
  VEHICLE_DETAILS,
  VEHICLE_DETAILS_PERMISSION,
  VIDEO,
  VIOLATION,
  UPDATE_PERMIT,
  UPDATE_INSURANCE,
  UPDATE_PUCC,
  USER_ACCESS_MANAGEMENT_PERMISSION,
  USER_ACCESS_MANAGEMENT,
  USER_LIST,
  ADD_USER,
  VIEW_USER,
  ADD_JUNCTION,
  JUNCTION_LIST,
  INCIDENT_LIST,
  INCIDENT_DETAILS,
} from "../../constants";
import { Typography } from "../BasicComponents";
import { logoutUser } from "../../helpers";
import VideoIcon from "../../assets/svg/video";

const Container = styled.div`
  .bodyWidth {
    width: calc(100% - 260px);
    margin-left: 260px;
  }
`;
const Sidebar = styled.div`
  width: 260px;
  position: fixed;
  top: 0;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.Secondary};
  color: #fff;
  .headerLogo {
    padding: 24px;
  }
  overflow-y: auto;
  .sidebarContent {
    height: calc(100vh - 80px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }
`;
const Option = styled.div`
  display: flex;
  min-height: 45px;
  margin: 12px 16px;
  padding: 12px 14px;
  border-radius: 4px;
  ${({ selected, theme }) =>
    selected &&
    `
    background-color: #fff;
    color:${theme.colors.Secondary};
  `}
  &:hover {
    background-color: #bec2bf;
    .logo {
      transform: scale(1.2);
      transition: transform 0.5s ease-in-out;
    }
  }
  .logo {
    height: 18px;
    width: 24px;
    margin-right: 8px;
  }
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  min-height: 45px;
  margin: 12px 16px;
  padding: 12px 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  ${({ selected, theme }) =>
    selected &&
    `
    background-color: #fff;
    color:${theme.colors.Secondary};
  `}
  &:hover {
    background-color: #bec2bf;
    .logo {
      transform: scale(1.2);
      transition: transform 0.5s ease-in-out;
    }
  }
  .logo {
    height: 24px;
    width: 24px;
    margin-right: 15px;
  }
`;

const SidebarWrapper = ({ children }) => {
  const [tab, setTab] = useState(null);

  const [searchParams] = useSearchParams();
  const pathname = useSelector((state) => state.currentNav.value);
  const resourceList = useSelector((state) => state.resources.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTab(searchParams.get("tab"));
  }, [searchParams]);

  return (
    <Container className="dF">
      <Sidebar>
        <div className="dF jcC headerLogo">
          <BeltechLogoIcon />
        </div>
        <div>
          <Link to={`/${HOME}`}>
            <Option selected={!tab && pathname === HOME}>
              <div className="logo">
                <HomeIcon
                  colorProp={
                    !tab && pathname === HOME
                      ? themeColor.colors.Secondary
                      : null
                  }
                />
              </div>
              <Typography modifiers="bt1">Home</Typography>
            </Option>
          </Link>
          {resourceList.includes(SIGNAL_CONTROL) && (
            <Link to={`/${SIGNAL_STATUS}`}>
              <Option selected={!tab && pathname === SIGNAL_STATUS}>
                <div className="logo">
                  <SignalIcon
                    colorProp={
                      !tab && pathname === SIGNAL_STATUS ? "#fff" : null
                    }
                  />
                </div>
                <Typography modifiers="bt1">Signal status</Typography>
              </Option>
            </Link>
          )}
          {resourceList.includes(FINANCE) && (
            <div>
              <Link to={`/${FINANCE}?tab=${OVERVIEW}`}>
                <Option selected={pathname === FINANCE}>
                  <div className="logo">
                    <FinanceIcon
                      colorProp={tab && pathname === FINANCE ? "#fff" : null}
                    />
                  </div>
                  <Typography modifiers="bt1">Finance</Typography>
                </Option>
              </Link>
            </div>
          )}
          {resourceList.includes(VIOLATION) && (
            <div>
              <Link to={`/${TRAFFIC_ANALYTICS}?tab=${OVERVIEW}`}>
                <Option selected={pathname === TRAFFIC_ANALYTICS}>
                  <div className="logo">
                    <ViolationIcon
                      colorProp={
                        tab && pathname === TRAFFIC_ANALYTICS ? "#fff" : null
                      }
                    />
                  </div>
                  <Typography modifiers="bt1">Traffic analytics</Typography>
                </Option>
              </Link>
            </div>
          )}
          {resourceList.includes(MAINTENANCE) && (
            <div>
              <Link to={`/${MAINTENANCE}?tab=${SIGNAL_SUMMARY}`}>
                <Option selected={pathname === MAINTENANCE}>
                  <div className="logo">
                    <MaintenanceIcon
                      colorProp={pathname === MAINTENANCE ? "#fff" : null}
                    />
                  </div>
                  <Typography modifiers="bt1">Maintenance</Typography>
                </Option>
              </Link>
            </div>
          )}
          {resourceList.includes(VIDEO) && (
            <div>
              <Link to={`/${VIDEO}?tab=${JUNCTION_VIDEOS}`}>
                <Option selected={pathname === VIDEO}>
                  <div className="logo">
                    <VideoIcon
                      colorProp={pathname === VIDEO ? "#1D2D4E" : "#fff"}
                    />
                  </div>
                  <Typography modifiers="bt1">Video</Typography>
                </Option>
              </Link>
            </div>
          )}
          {resourceList.includes(VEHICLE_DETAILS_PERMISSION) && (
            <div>
              <Link to={`/${VEHICLE_DETAILS}`}>
                <Option selected={pathname === VEHICLE_DETAILS}>
                  <div className="logo">
                    <VehicleDetail
                      colorProp={
                        pathname === VEHICLE_DETAILS ? "#1D2D4E" : "#fff"
                      }
                    />
                  </div>
                  <Typography modifiers="bt1">Vehicle details</Typography>
                </Option>
              </Link>
            </div>
          )}
          {resourceList.includes(UPDATE_VEHICLE_DETAILS_PERMISSION) && (
            <div>
              <Link to={`/${UPDATE_VEHICLE_DETAILS}`}>
                {console.log("Logging the value of pathname,", pathname)}
                <Option
                  selected={
                    pathname === UPDATE_VEHICLE_DETAILS ||
                    pathname === UPDATE_PERMIT ||
                    pathname === UPDATE_INSURANCE ||
                    pathname === UPDATE_PUCC
                  }
                >
                  <div className="logo">
                    <UpdateVehicle
                      colorProp={
                        pathname === UPDATE_VEHICLE_DETAILS ||
                        pathname === UPDATE_PERMIT ||
                        pathname === UPDATE_INSURANCE ||
                        pathname === UPDATE_PUCC
                          ? "#1D2D4E"
                          : "#fff"
                      }
                    />
                  </div>
                  <Typography modifiers="bt1">
                    Update vehicle details
                  </Typography>
                </Option>
              </Link>
            </div>
          )}
          {resourceList.includes(USER_ACCESS_MANAGEMENT_PERMISSION) && (
            <div>
              <Link to={`/${USER_ACCESS_MANAGEMENT}`}>
                <Option
                  selected={
                    pathname === USER_ACCESS_MANAGEMENT ||
                    pathname === USER_LIST ||
                    pathname === ADD_USER ||
                    pathname === VIEW_USER ||
                    pathname === ADD_JUNCTION ||
                    pathname === JUNCTION_LIST
                  }
                >
                  <div className="logo">
                    <UserAccessManagement
                      colorProp={
                        pathname === USER_ACCESS_MANAGEMENT ||
                        pathname === USER_LIST ||
                        pathname === ADD_USER ||
                        pathname === VIEW_USER ||
                        pathname === ADD_JUNCTION ||
                        pathname === JUNCTION_LIST
                          ? "#1D2D4E"
                          : "#fff"
                      }
                    />
                  </div>
                  <Typography modifiers="bt1">User access</Typography>
                </Option>
              </Link>
            </div>
          )}
          <div>
            <Link to={`/${INCIDENT_LIST}`}>
              <Option
                selected={
                  pathname === INCIDENT_LIST || pathname === INCIDENT_DETAILS
                }
              >
                <div className="logo">
                  <IncidentIcon
                    colorProp={
                      pathname === INCIDENT_LIST ||
                      pathname === INCIDENT_DETAILS
                        ? "#1D2D4E"
                        : "#fff"
                    }
                  />
                </div>
                <Typography modifiers="bt1">Incidents</Typography>
              </Option>
            </Link>
          </div>
          <div>
            <ButtonWrapper onClick={() => logoutUser(dispatch, navigate)}>
              <div className="logo">
                <LogoutIcon
                  colorProp={pathname === MAINTENANCE ? "1D2D4E" : null}
                />
              </div>
              <Typography modifiers="bt1">Logout</Typography>
            </ButtonWrapper>
          </div>
        </div>
      </Sidebar>
      <div className="bodyWidth">{children}</div>
    </Container>
  );
};
export default SidebarWrapper;
SidebarWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};
