const color = {
  Primary: "#3179E4",
  Secondary: "#1D2D4E",
  Tertiary: "#F7F8F9",
  Quaternary: "#555555",
  Pentanary: "#41A7AD",
  Quinary: "#5B7194",
  TextAlpha: "#2D2D32",
  TextBeta: "#1D2D4E",
  TextDescription: "#555555",
  Success: "#43A659",
  Failure: "#FB0641",
  Message: "#FFBC0F",
  Disabled: "#D8D8D8",
  Neutral: {
    100: "#D8D8D8",
    200: "#DDD",
    300: "#EEE",
    400: "#F2F2F2",
    500: "#F8F8F8",
    600: "#FAFAFA",
  },
  Red100: "#FFE0DD",
  Red500: "#F52C68",
  Green100: "#D6F8F1",
  Green500: "#009D7D",
  Blue100: "#D6E6FF",
  Blue500: "#3179E4",
  Yellow100: "#FFECD6",
  Yellow500: "#FF8A00",
  Black: "#000000",
  Black100: "#1D2D4E",
  Grey100: "#D9D9D9",
};
export default color;
